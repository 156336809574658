import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Box, Container, Flex, FlexList, Heading, Space, Subhead, Text } from "../components/ui"
import * as styles from "../components/ui.css"

interface ContactProps {
  data: {
    datoCmsContact: {
      exhibitions: {
        year: number
        exhibitions: {
          gallery: string
          month: string
          title: string
        }[]
      }[]
      presentations: {
        year: number
        presentations: {
          month: string
          title: string
        }[]
      }[]
      publications: {
        year: number
        publications: {
          month: string
          title: string
          link: string | null
        }[]
      }[]
    }
  }
}

const monthMap = {
  jan: 1,
  feb: 2,
  mar: 3,
  apr: 4,
  may: 5,
  jun: 6,
  jul: 7,
  aug: 8,
  sep: 9,
  oct: 10,
  nov: 11,
  dec: 12,
}

export default function Contact(props: ContactProps) {
  const { datoCmsContact } = props.data

  const [exhibitionsOpen, setExhibitionsOpen] = React.useState(true);
  const [presentationsOpen, setPresentationsOpen] = React.useState(false);
  const [publicationsOpen, setPublicationsOpen] = React.useState(false);

  const [showAllExhibitions, setShowAllExhibitions] = React.useState(false);

  let exhibitions = datoCmsContact.exhibitions
    .sort((x, y) => y.year - x.year);

  if (!showAllExhibitions) {
    exhibitions = exhibitions.slice(0, 4);
  }

  return (
    <Layout title="Contact">
      <Container>
        <Heading as="h1">
          CV
        </Heading>
        <a className={styles.link} href="#" onClick={(e) => { e.preventDefault(); setExhibitionsOpen(!exhibitionsOpen); }}>
          <Subhead as="h2">
            {exhibitionsOpen ? "- " : "+ "}
            Selected Exhibitions
          </Subhead>
        </a>
        {
          exhibitionsOpen &&
          <>
            {
              exhibitions
                .map(x =>
                  <FlexList key={`year-${x.year}`} marginY={3} variant="column" alignItems="start">
                    <Subhead as="h3">
                      {x.year}
                    </Subhead>

                    {
                      x.exhibitions
                        .sort((x, y) => monthMap[x.month] - monthMap[y.month])
                        .map(x =>
                          <Flex>
                            <Box style={{ alignSelf: "baseline", width: 100 }}>
                              <Text variant="kicker">
                                {x.month}
                              </Text>
                            </Box>
                            <Box width="full" style={{ alignSelf: "baseline" }}>
                              <Text variant="bold">
                                {x.title}
                              </Text>

                              <Text variant="small">
                                {x.gallery}
                              </Text>
                            </Box>
                          </Flex>
                        )
                    }
                  </FlexList>
                )
            }

            <a className={styles.link} href="#" onClick={(e) => { e.preventDefault(); setShowAllExhibitions(!showAllExhibitions); }}>
              {
                showAllExhibitions ? "Show less" : "Show more"
              }
            </a>
          </>
        }

        <Space size={4} />

        <a className={styles.link} href="#" onClick={(e) => { e.preventDefault(); setPublicationsOpen(!publicationsOpen); }}>
          <Subhead as="h2">
            {publicationsOpen ? "- " : "+ "}
            Selected Publications
          </Subhead>
        </a>
        {
          publicationsOpen &&
          datoCmsContact.publications
            .sort((x, y) => y.year - x.year)
            .map(x =>
              <FlexList key={`year-${x.year}`} marginY={3} variant="column" alignItems="start">
                <Subhead as="h3">
                  {x.year}
                </Subhead>

                {
                  x.publications
                    .sort((x, y) => monthMap[x.month] - monthMap[y.month])
                    .map(x =>
                      <Flex>
                        <Box style={{ alignSelf: "baseline", width: 100 }}>
                          <Text variant="kicker">
                            {x.month}
                          </Text>
                        </Box>
                        <Box width="full" style={{ alignSelf: "baseline" }}>
                          <Text variant="bold">
                            {x.link ? <a href={x.link}>{x.title}</a> : x.title}
                          </Text>
                        </Box>
                      </Flex>
                    )
                }
              </FlexList>
            )
        }

        <Space size={4} />

        <a className={styles.link} href="#" onClick={(e) => { e.preventDefault(); setPresentationsOpen(!presentationsOpen); }}>
          <Subhead as="h2">
            {presentationsOpen ? "- " : "+ "}
            Selected Presentations
          </Subhead>
        </a>
        {
          presentationsOpen &&
          datoCmsContact.presentations
            .sort((x, y) => y.year - x.year)
            .map(x =>
              <FlexList key={`year-${x.year}`} marginY={3} variant="column" alignItems="start">
                <Subhead as="h3">
                  {x.year}
                </Subhead>

                {
                  x.presentations
                    .sort((x, y) => monthMap[x.month] - monthMap[y.month])
                    .map(x =>
                      <Flex>
                        <Box style={{ alignSelf: "baseline", width: 100 }}>
                          <Text variant="kicker">
                            {x.month}
                          </Text>
                        </Box>
                        <Box width="full" style={{ alignSelf: "baseline" }}>
                          <Text variant="bold">
                            {x.title}
                          </Text>
                        </Box>
                      </Flex>
                    )
                }
              </FlexList>
            )
        }

        <Space size={4} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    datoCmsContact {
      exhibitions {
        year
        exhibitions {
          gallery
          month
          title
        }
      }
      presentations {
        year
        presentations {
          month
          title
        }
      }
      publications {
        year
        publications {
          month
          title
          link
        }
      }
    }
  }
`
